@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'),
    url(./assets/fonts/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Thin';
  src: local('Poppins-Thin'),
    url(./assets/fonts/Poppins/Poppins-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'),
    url(./assets/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'),
    url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'),
    url(./assets/fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'),
    url(./assets/fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

* {
  font-family: 'Poppins-Regular';
}

.ant-table {
  white-space: nowrap;
}

.pms-bg {
  background-color: #95479b !important;
  border-color: #870491 !important;
}
