.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('../../assets/bg1.jpg') no-repeat center center fixed;
  background-size: cover;
}

.Auth-form {
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 40px;
  background-color: rgba(250, 250, 250, 0.5);
  margin: 0 20px;
}
